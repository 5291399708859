import React, { useState } from 'react';
import { string, func, object, array, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { intlShape, injectIntl } from '../../util/reactIntl';
import { LISTING_TYPE_COMPANY, propTypes } from '../../util/types';
import {
  ensureCompanySearchOrUserType,
  ensureListing,
  ensureUser,
  removeLastInitial,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';

import { NamedLink, IconHome, IconCouch, Modal } from '../../components';

import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import css from './ListingCard.module.css';
import { generateSheetsRow } from '../../containers/ListingPage/ListingPage.shared';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const INTERIOR_DESIGN_KEY = 'interior-design';

const TALENT_ASPECT_RATIO_WIDTH = 4;
const TALENT_ASPECT_RATIO_HEIGHT = 5;

const findListingField = (fields, key) => fields.find(field => field.key === key)?.enumOptions;
const findExtendedDataValue = (options, valueKey) =>
  options.find(option => option.option === valueKey)?.label;
const filterExtendedDataValues = (options, valueKeys) =>
  options.filter(o => valueKeys?.includes(o.option)).map(o => o.label);

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    listing,
    setActiveListing,
    type,
    mapPinList,
    usersWithTransactions,
    inquiryModalOpen,
    setInquiryModalOpen,
    onManageDisableScrolling,
    routeConfiguration,
    history,
    setCommonParams,
    setSheetsRow,
    currentUser,
    setInquiryTitle,
  } = props;
  // listing & author
  const currentListing = ensureListing(listing);
  const currentAuthor = ensureUser(listing.author);
  const { title = '', publicData } = currentListing.attributes;
  const { displayName } = currentAuthor.attributes.profile;
  const profileImage = currentAuthor.profileImage;

  const [mouseSelected, setMouseSelected] = useState(null);
  const isMapPined = listing?.id?.uuid === mapPinList?.uuid;

  // listing fields
  const isCompanyUser = ensureCompanySearchOrUserType(type);
  const listingTitle = isCompanyUser ? title : removeLastInitial(displayName);

  const address = publicData?.address;
  const city = address?.city;

  const listingFields = config.listing.listingFields;
  const industry = listingFields.find(f => f.key === 'industry');
  const industryOptions = industry.enumOptions;
  const industryFromPublicData = publicData?.industry;
  const currentIndustry = industryOptions.find(o => o.option === industryFromPublicData);

  const isInteriorDesign = currentIndustry ? currentIndustry.option === INTERIOR_DESIGN_KEY : null;

  const { primarySpecialty, openTo, style, wfhPolicy, availability, experience } = publicData;

  const companySizeOptions = findListingField(listingFields, 'companySize');
  const companySize = findExtendedDataValue(companySizeOptions, publicData.companySize || null);

  // images
  const hasImages = currentListing.images && currentListing.images.length > 0;
  const images = profileImage
    ? hasImages
      ? [profileImage, ...currentListing.images]
      : [profileImage]
    : currentListing.images;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectWidthNum = isCompanyUser ? aspectWidth : TALENT_ASPECT_RATIO_WIDTH;
  const aspectHeightNum = isCompanyUser ? aspectHeight : TALENT_ASPECT_RATIO_HEIGHT;
  const variants = images?.[0]
    ? Object.keys(images?.[0]?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];
  const linkParams = { id: currentListing.id.uuid, slug: createSlug(listingTitle), type };

  const commonParams = { params: linkParams, history, routes: routeConfiguration };
  const sheetsRow = generateSheetsRow(currentUser, currentAuthor);
  const authorDisplayName = userDisplayNameAsString(currentAuthor, '');

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => {
        setActiveListing(currentListing.id);
        setMouseSelected(true);
      },
      onMouseLeave: () => {
        setActiveListing(null);
        setMouseSelected(false);
      },
    }
    : null;

  const isConnected = !!usersWithTransactions.find(user => user.relationships.provider.data.id.uuid === currentAuthor.id.uuid);
  const classes = classNames(
    rootClassName || css.root,
    className,
    {
      // [css.companyListingCard]: isCompanyUser,
      [css.mapPinList]: isMapPined || mouseSelected,
    },
    css.companyListingCard
  );

  const maxVisibleItems = 5;
  const mixedMiddleItem = [];
  if (wfhPolicy) mixedMiddleItem.push(...wfhPolicy);
  if (experience) {
    const newExperience = experience.map(e => e + '+ years');
    mixedMiddleItem.push(...newExperience);
  }
  if (availability) mixedMiddleItem.push(...availability);
  if (openTo) mixedMiddleItem.push(openTo);
  if (companySize) mixedMiddleItem.push(companySize);
  if (primarySpecialty) mixedMiddleItem.push(primarySpecialty);
  if (style && Array.isArray(style) && style.length > 0) mixedMiddleItem.push(...style);

  const visibleItems = mixedMiddleItem.slice(0, maxVisibleItems);
  const remainingCount = mixedMiddleItem.length - maxVisibleItems;

  const handleButtonClick = event => {
    event.preventDefault(); // Prevents the Link from being triggered
    event.stopPropagation(); // Stops event propagation to the Link
    setInquiryModalOpen(true);
    setCommonParams(commonParams);
    setSheetsRow(sheetsRow);
    setInquiryTitle(authorDisplayName);
  };

  return (
    <div>
      <div className={classes} {...setActivePropsMaybe}>
        <ListingImageGallery
          images={images}
          aspectHeight={aspectHeightNum}
          aspectWidth={aspectWidthNum}
          imageVariants={variants}
          linkParams={linkParams}
        />
        <NamedLink className={css.info} name="ListingPage" params={linkParams}>
          <div className={css.secondaryInfo}>
            {/* <span className={css.city}>{city}</span> */}
            <div>
              {richText(listingTitle, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>

            <div className={css.infoIcons}>
              {isInteriorDesign ? (
                <IconCouch className={css.infoIcon} />
              ) : (
                <IconHome className={css.infoIcon} />
              )}
            </div>
          </div>
          <div className={css.mainInfo}>
            {/* <div className={css.title}>
            {richText(listingTitle, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div> */}
            <div className={css.title}>{industryFromPublicData}</div>

            <div className={css.listCardMiddle}>
              {visibleItems &&
                visibleItems.map((v, index) => (
                  <div key={index} className={css.listingCardDetails}>
                    {v === 'both'
                      ? isCompanyUser
                        ? 'both candidates'
                        : 'Both positions'
                      : v === 'part-time'
                        ? isCompanyUser
                          ? v + ' candidates'
                          : v + ' positions'
                        : v === 'full-time'
                          ? isCompanyUser
                            ? v + ' candidates'
                            : v + ' positions'
                          : v}
                  </div>
                ))}
              {remainingCount > 0 && (
                <div className={css.listingCardDetails}>+{remainingCount}</div>
              )}
            </div>

            <div className={css.listCardBttm}>
              <span className={css.city}>{city}</span>
              {isConnected ? (
                <div className={css.isConnected}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M10.39 0.774861L9.6116 1.5533L9.3699 1.3187C9.12464 1.0841 8.55592 0.685999 8.4635 0.685999C8.43506 0.685999 8.39952 0.671781 8.37819 0.657563C8.3462 0.629127 8.10094 0.550928 7.80947 0.476283C7.46824 0.387421 6.6507 0.447847 6.30947 0.586473C5.73009 0.82107 5.51326 0.977468 4.84146 1.63505C4.53578 1.93363 4.26563 2.17889 4.2372 2.17889C4.21232 2.17889 4.09857 2.08647 3.98483 1.97628L3.77867 1.77012L3.35924 2.18955L2.94336 2.60543L6.16018 5.82225C7.93032 7.59239 9.39122 9.03552 9.41255 9.03552C9.43032 9.03197 9.62227 8.84713 9.83909 8.62675L10.2265 8.22154L10.0239 8.01538C9.91374 7.90164 9.82132 7.78789 9.82132 7.76301C9.82132 7.73457 10.0666 7.46443 10.3652 7.15875C11.0547 6.45495 11.2182 6.22391 11.396 5.68718C11.5346 5.27486 11.563 5.11135 11.563 4.73813C11.563 4.25827 11.4564 3.79974 11.2538 3.4123C11.2253 3.35898 11.1827 3.27723 11.1614 3.23458C11.0903 3.09951 10.8877 2.85069 10.6602 2.61609L10.4469 2.39216L11.2253 1.61017L12.0002 0.828178L11.7905 0.625572C11.6768 0.511828 11.4884 0.32344 11.3746 0.209696L11.1685 -2.00272e-05L10.39 0.774861ZM7.81658 1.6777C8.21468 1.83055 8.31421 1.90875 9.20639 2.79737C10.0453 3.63268 10.1092 3.70732 10.2336 3.9668C10.4931 4.5142 10.4611 5.12202 10.1519 5.65519C10.063 5.80448 9.38056 6.51538 9.06066 6.79263L8.92203 6.91349L7.00971 5.00116C5.69099 3.68244 5.10805 3.07462 5.12227 3.03908C5.13293 3.00709 5.3853 2.74406 5.68033 2.44903C6.2135 1.91586 6.29525 1.85543 6.76445 1.65282C6.81421 1.6315 7.03104 1.61372 7.24431 1.61372C7.54999 1.61017 7.6744 1.62439 7.81658 1.6777Z"
                      fill="#B70201"
                    />
                    <path
                      d="M2.16114 3.37323L1.7737 3.77844L1.9763 3.9846C2.08649 4.09834 2.17891 4.21209 2.17891 4.23697C2.17891 4.26185 1.93009 4.5391 1.63152 4.84834C1.32938 5.15759 1.01303 5.51303 0.931279 5.63389C0.42654 6.37322 0.298578 7.3436 0.590047 8.22157C0.611374 8.2891 0.643365 8.36019 0.657583 8.38152C0.671801 8.39929 0.686019 8.43839 0.686019 8.46683C0.686019 8.54147 1.11967 9.17062 1.32583 9.391C1.4218 9.49763 1.50355 9.60071 1.50355 9.62204C1.50355 9.64337 1.16588 9.99882 0.75 10.4147L0 11.1682L0.209716 11.3744C0.32346 11.4882 0.511848 11.673 0.625592 11.7903L0.831753 12L1.60663 11.2287L2.38152 10.4573L2.53791 10.6066C2.72275 10.7773 3.12441 11.1007 3.22749 11.1576C3.79265 11.4597 4.17654 11.5628 4.73104 11.5628C5.3282 11.5628 5.89692 11.3886 6.37322 11.0652C6.50118 10.9799 6.85308 10.6635 7.15877 10.3649C7.46445 10.0664 7.73459 9.82109 7.76303 9.82109C7.78791 9.82109 7.90166 9.91351 8.0154 10.0237L8.22156 10.2299L8.64099 9.81043L9.05687 9.39455L5.84005 6.17773C4.0699 4.40759 2.609 2.96446 2.58768 2.96446C2.5699 2.96801 2.37796 3.15285 2.16114 3.37323ZM6.88151 8.9609C6.8673 8.98934 6.61493 9.25592 6.3199 9.55806C5.83649 10.0379 5.74763 10.1161 5.50237 10.2299C5.10782 10.4182 4.56398 10.468 4.22275 10.3472C3.81043 10.2014 3.73934 10.1446 2.79739 9.20261C2.04739 8.45261 1.85545 8.23934 1.78081 8.07939C1.52488 7.52844 1.52133 7.00237 1.7737 6.49052C1.89099 6.25237 1.98341 6.14218 2.44905 5.67654C2.74763 5.38152 3.01066 5.12915 3.03554 5.11849C3.10663 5.0936 6.9064 8.88981 6.88151 8.9609Z"
                      fill="#B70201"
                    />
                  </svg>
                  CONNECTED
                </div>
              ) :
                (
                  <div className={css.connectBtn} onClick={handleButtonClick}>
                    Connect
                  </div>
                )}
            </div>

            {/* {isCompanyUser ? null : currentIndustry ? (
            <div className={css.industry}>
              <span className={css.industryText}>
                {isInteriorDesign ? (
                  <IconCouch className={css.infoIcon} />
                ) : (
                  <IconHome className={css.infoIcon} />
                )}
                {currentIndustry?.label}
              </span>
            </div>
          ) : null} */}
          </div>
        </NamedLink>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  type: LISTING_TYPE_COMPANY,
  mapPinList: null,
  usersWithTransactions: [],
  inquiryModalOpen: false,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  type: propTypes.listingType.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
  mapPinList: object,
  usersWithTransactions: array,
  inquiryModalOpen: bool,
  setInquiryModalOpen: func,
  onManageDisableScrolling: func,
};

export default injectIntl(ListingCardComponent);
