import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { func, object, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import util modules
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

// Import shared components
import {
  FieldPhoneNumberInput,
  FieldRadioButtonGroup,
  FieldTextInput,
  Form,
  SecondaryButton,
} from '../../../components';
// Import modules from this directory
import { OnChange } from 'react-final-form-listeners';
import { useConfiguration } from '../../../context/configurationContext';
import css from './SignupForm.module.css';

const TAB_LISTING_FIELD_IDS = ['industry', 'primarySpecialty', 'secondarySpecialty', 'interests'];

const ARCHITECTURE_KEY = 'architecture';
const INDUSTRY_BOTH_COMBINED_KEY = 'architecture,interior-design,both';
const INDUSTRY_BOTH_KEY = 'both';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, submitFormError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentWorkForm.updateFailed" />
  ) : null;
  const submitFormErrorMessage = submitFormError ? (
    <FormattedMessage id="CreateTalentWorkForm.submitFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  } else if (submitFormError) {
    return <p className={css.error}>{submitFormErrorMessage}</p>;
  }
  return null;
};

const SignUpFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    validate={() => {
      const errors = {};
      if (props.isEmailTaken) {
        const emailTakenMessage = props.intl.formatMessage({
          id: 'CreateCompanyBasicsForm.emailTaken',
        });
        errors.email = emailTakenMessage;
      }
      return errors;
    }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        formId,
        handleSubmit,
        //   onRedirectToPreviousTab,
        onEmailChangeWithDebounce,
        isEmailTaken,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        initialValues,
      } = formRenderProps;

      useEffect(() => {
        if(isEmailTaken===true){
          const {email}= initialValues||{};
          // form.change('email', email);
          // You might also want to set it as touched to trigger validation
          form.blur('email'); // Ensure it is considered as touched
          form.focus('email'); // Optionally focus back for better UX
        }},[isEmailTaken]);

      const config = useConfiguration();
      const { listingFields } = config?.listing || {};
      const listingFieldsRegisterType = listingFields.find(l => l.key === 'registerType');
      const registerOptions = listingFieldsRegisterType
        ? listingFieldsRegisterType.enumOptions
        : [];
        
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      const lastNameRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const emailRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'CreateTalentBasicsForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'CreateTalentBasicsForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const confirmPasswordRequiredMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.confirmPasswordRequired',
      });
      const confirmPasswordInvalidMessage = intl.formatMessage({
        id: 'CreateTalentBasicsForm.confirmPasswordInvalid',
      });
      const confirmPasswordRequired = validators.required(confirmPasswordRequiredMessage);
      const confirmPasswordInvalid = validators.passwordMatch(
        confirmPasswordInvalidMessage,
        values.password
      );
      const confirmPasswordValidators = validators.composeValidators(
        confirmPasswordRequired,
        confirmPasswordInvalid
      );

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      // const submitDisabled = invalid || disabled || submitInProgress || !isEmailTaken;
      const { registerType, email, firstName, lastName, name } = values;
      const submitDisabled =
        registerType === 'candidate' ? !email || !firstName || !lastName ||isEmailTaken : !email || !name || isEmailTaken;
        
      const NAME_MAX_LENGTH = 60;
      const nameRequiredMessage = intl.formatMessage({
        id: 'CreateCompanyBasicsForm.nameRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'CreateCompanyBasicsForm.maxLength' },
        {
          maxLength: NAME_MAX_LENGTH,
        }
      );
      const maxLength60Message = validators.maxLength(maxLengthMessage, NAME_MAX_LENGTH);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <ErrorMessage fetchErrors={fetchErrors} />

          <div className={css.fieldsWrapper}>
            <FieldRadioButtonGroup
              className={css.field}
              id={`${formId}.${listingFieldsRegisterType.key}`}
              name={listingFieldsRegisterType.key}
              label={listingFieldsRegisterType.saveConfig.label}
              options={registerOptions.map(o => ({
                key: o.option,
                label: o.label,
              }))}
              customListClassName={css.radioFlex}
              customLabelInput={css.customLabelInput}
              customItem={css.customItem}
            />
            {registerType === 'candidate' ? (
              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.firstName`}
                  label="First name"
                  name="firstName"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.firstNamePlaceholder',
                  })}
                  validate={firstNameRequired}
                  inputClassName={css.inputStyle}
                  customLabelInput={css.customLabelInput}
                />
                <FieldTextInput
                  id={`${formId}.lastName`}
                  label="Last name"
                  name="lastName"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.lastNamePlaceholder',
                  })}
                  validate={lastNameRequired}
                  inputClassName={css.inputStyle}
                  customLabelInput={css.customLabelInput}
                />
              </div>
            ) : (
              <div>
                <FieldTextInput
                  id={`${formId}.name`}
                  name="name"
                  label="Company name"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.namePlaceholder',
                  })}
                  maxLength={NAME_MAX_LENGTH}
                  validate={validators.composeValidators(
                    validators.required(nameRequiredMessage),
                    maxLength60Message
                  )}
                  inputClassName={css.inputStyle}
                  customLabelInput={css.customLabelInput}
                />
              </div>
            )}

            <div className={css.fieldFlex}>
              <FieldTextInput
                id={`${formId}.email`}
                label="Email"
                name="email"
                className={css.field}
                type="email"
                placeholder={intl.formatMessage({ id: 'CreateTalentBasicsForm.emailPlaceholder' })}
                validate={validators.composeValidators(emailRequired, emailValid)}
                inputClassName={css.inputStyle}
                customLabelInput={css.customLabelInput}
              />
              <OnChange name="email">
                {(value, previous) => onEmailChangeWithDebounce({ email: value })}
              </OnChange>
            </div>
            {registerType === 'candidate' ? (
              <div>
                <FieldPhoneNumberInput
                  id={`${formId}.phone`}
                  label="Phone number"
                  name="phone"
                  className={css.field}
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.phonePlaceholder',
                  })}
                  inputClassName={css.inputStyle}
                  customLabelInput={css.customLabelInput}
                />
              </div>
            ) : null}
          </div>

          <SecondaryButton
            className={css.landingpageSubmitButton}
            type="submit"
            // inProgress={submitInProgress}
            disabled={submitDisabled}
            // ready={submitReady}
          >
            {saveActionMsg}
          </SecondaryButton>
        </Form>
      );
    }}
  />
);

SignUpFormComponent.defaultProps = {
  className: null,
  formId: 'SignupForm',
  fetchErrors: null,
};

SignUpFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  // onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  fetchErrors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
  }).isRequired,
};

export default compose(injectIntl)(SignUpFormComponent);
