import { login, signupError, signupSuccess } from '../../ducks/auth.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { CONNECTION_PROCESS_NAME } from '../../transactions/transaction';
import {
  checkMailChimpStatus,
  createBackendRegistrationLog,
  createMailChimpMember,
  createMongoDBLog,
  googleSpreadsheetAPI,
  updateMailChimpMember,
} from '../../util/api';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { LISTING_TYPE_TALENT } from '../../util/types';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => payload => ({ type: actionType, payload });
const errorAction = actionType => payload => ({ type: actionType, payload, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/CreateTalentPage/MARK_TAB_UPDATED';
export const CLEAR_UPDATED_TAB = 'app/CreateTalentPage/CLEAR_UPDATED_TAB';
export const CLEAR_PROFILE_DATA = 'app/CreateTalentPage/CLEAR_PROFILE_DATA';
export const LOAD_PROFILE_DATA = '/app/CreateTalentPage/LOAD_PROFILE_DATA';

export const CREATE_PROFILE_DRAFT_REQUEST = 'app/CreateTalentPage/CREATE_PROFILE_DRAFT_REQUEST';
export const CREATE_PROFILE_DRAFT_SUCCESS = 'app/CreateTalentPage/CREATE_PROFILE_DRAFT_SUCCESS';
export const CREATE_PROFILE_DRAFT_ERROR = 'app/CreateTalentPage/CREATE_PROFILE_DRAFT_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/CreateTalentPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/CreateTalentPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/CreateTalentPage/UPDATE_PROFILE_ERROR';

export const PUBLISH_PROFILE_REQUEST = 'app/CreateTalentPage/PUBLISH_PROFILE_REQUEST';
export const PUBLISH_PROFILE_SUCCESS = 'app/CreateTalentPage/PUBLISH_PROFILE_SUCCESS';
export const PUBLISH_PROFILE_ERROR = 'app/CreateTalentPage/PUBLISH_PROFILE_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateInProgress: false,
  updateError: null,
  profileData: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case MARK_TAB_UPDATED:
      return { ...state, updatedTab: payload };
    case CLEAR_UPDATED_TAB:
      return { ...state, updatedTab: null, updateError: null };
    case CLEAR_PROFILE_DATA:
      return {
        ...state,
        profileData: null,
      };
    case LOAD_PROFILE_DATA:
      return {
        ...state,
        profileData: payload ? payload : state.profileData,
      };

    // Create profile draft
    case CREATE_PROFILE_DRAFT_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        createListingDraftError: null,
      };
    case CREATE_PROFILE_DRAFT_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        createListingDraftError: null,
        profileData: payload,
      };
    case CREATE_PROFILE_DRAFT_ERROR:
      return {
        ...state,
        updateInProgress: false,
        createListingDraftError: payload,
      };

    // Update profile
    case UPDATE_PROFILE_REQUEST:
      return { ...state, updateInProgress: true, updateListingError: null };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        updateListingError: null,
        profileData: { ...state.profileData, ...payload },
      };
    case UPDATE_PROFILE_ERROR:
      return { ...state, updateInProgress: false, updateListingError: payload };

    // Publish profile
    case PUBLISH_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        publishListingError: null,
      };
    case PUBLISH_PROFILE_SUCCESS:
      return {
        ...state,
        updateInProgress: false,
        publishListingError: null,
      };
    case PUBLISH_PROFILE_ERROR: {
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        updateInProgress: false,
        publishListingError: payload,
      };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
  type: MARK_TAB_UPDATED,
  payload: tab,
});

export const clearUpdatedTab = () => ({
  type: CLEAR_UPDATED_TAB,
});

export const clearProfileData = () => ({
  type: CLEAR_PROFILE_DATA,
});

export const loadProfileData = profileData => ({
  type: LOAD_PROFILE_DATA,
  payload: profileData,
});

// All the action creators that don't have the {Success, Error} suffix
// take the params object that the corresponding SDK endpoint method
// expects.
export const createProfileDraftRequest = requestAction(CREATE_PROFILE_DRAFT_REQUEST);
export const createProfileDraftSuccess = successAction(CREATE_PROFILE_DRAFT_SUCCESS);
export const createProfileDraftError = errorAction(CREATE_PROFILE_DRAFT_ERROR);

export const publishProfileRequest = requestAction(PUBLISH_PROFILE_REQUEST);
export const publishProfileSuccess = successAction(PUBLISH_PROFILE_SUCCESS);
export const publishProfileError = errorAction(PUBLISH_PROFILE_ERROR);

export const updateProfileRequest = requestAction(UPDATE_PROFILE_REQUEST);
export const updateProfileSuccess = successAction(UPDATE_PROFILE_SUCCESS);
export const updateProfileError = errorAction(UPDATE_PROFILE_ERROR);

// ================ Thunk ================ //

export const saveProfileDataToLocalStorage = profileData => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined') {
    console.log('Cannot access localStorage: window is not defined (server-side).');
  } else {
    try {
      const profileDataFromReduxState = getState().CreateTalentPage.profileData;
      const { confirmPassword, password, images, profileImage, ...profileDataForLocalStorage } = profileData;
      const combinedProfileData = {
        ...profileDataFromReduxState,
        ...profileDataForLocalStorage,
      }
      const jsonData = JSON.stringify(combinedProfileData);
      localStorage.setItem(`${LISTING_TYPE_TALENT}ProfileData`, jsonData);
      createBackendRegistrationLog({ data: combinedProfileData, email: combinedProfileData.email  });
    } catch (error) {
      console.error('Error saving user data to localStorage:', error);
    }
  }
};

export const removeProfileDataFromLocalStorage = () => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined') {
    console.log('Cannot access localStorage: window is not defined (server-side).');
  } else {
    try {
      localStorage.removeItem(`${LISTING_TYPE_TALENT}ProfileData`);
    } catch (error) {
      console.error('Error removing user data from localStorage:', error);
    }
  }
};

export const loadProfileDataFromLocalStorage = () => (dispatch, getState, sdk) => {
  if (typeof window === 'undefined') {
    console.log('Cannot access localStorage: window is not defined (server-side).');
  } else {
    try {
      const profileDataFromLocalStorage = localStorage.getItem(`${LISTING_TYPE_TALENT}ProfileData`);
      const profileData = profileDataFromLocalStorage
        ? JSON.parse(profileDataFromLocalStorage)
        : null;

      dispatch(loadProfileData(profileData));
      return profileData;
    } catch (error) {
      console.error('Error loading user data from localStorage:', error);
    }
  }
};

export function requestCreateProfileDraft(data) {
  return async (dispatch, getState, sdk) => {
    dispatch(createProfileDraftRequest(data));
    try {
      dispatch(saveProfileDataToLocalStorage(data));
      return new Promise(resolve => resolve(dispatch(createProfileDraftSuccess(data))));
    } catch (e) {
      log.error(e, 'create-listing-draft-error', { listingData: data });
      return dispatch(createProfileDraftError(storableError(e)));
    }
  };
}

export function requestUpdateProfile(tab, data, config) {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest(data));
    try {
      dispatch(saveProfileDataToLocalStorage(data));
      return new Promise(resolve => resolve(dispatch(updateProfileSuccess(data))));
    } catch (e) {
      log.error(e, 'update-listing-error', { listingData: data });
      dispatch(updateProfileError(storableError(e)));
    }
  };
}

const uploadProfileImage = profileImage => (dispatch, getState, sdk) => {
  return dispatch(uploadImage(profileImage)).then(imageResponse => {
    const imageId = imageResponse.data.data.id;

    // Attach uploaded image to the currentUser
    return sdk.currentUser.updateProfile({
      profileImageId: imageId,
    });
  });
};

const uploadImage = file => (dispatch, getState, sdk) => {
  // Upload image to the Flex API
  return sdk.images.upload({
    image: file,
  });
};

export const denormalizeAndUploadImages = imageFiles => async (dispatch, getState, sdk) => {
  const images = [];
  console.log(images);
  for (const image of imageFiles) {
    const uploadedImageResponse = image.name ? await dispatch(uploadImage(image)) : null;
    images.push(uploadedImageResponse ? uploadedImageResponse.data.data.id.uuid : image);
  }

  return await Promise.all(images);
};


const finalProcess = async (sdk, dispatch, images, newListing) => {

  dispatch(publishProfileSuccess());
  dispatch(removeProfileDataFromLocalStorage());
  return dispatch(addMarketplaceEntities(newListing));

  try {
    // Denormalize and upload project images
    if (images && images?.length > 0) {
      const imagesArray = [];
      for (const image of images) {
        const uploadedImageResponse = image.name ? await dispatch(uploadImage(image)) : null;
        imagesArray.push(uploadedImageResponse ? uploadedImageResponse.data.data.id.uuid : image);
      }

      await Promise.all(imagesArray)
        .then(async response => {
          // Get image IDs from denormalized projects
          const imageIds = response ? response : [];
          console.log({
            id: newListing.data.data.id.uuid,
            images: imageIds,
          });
          await sdk.ownListings.update({
            id: newListing.data.data.id.uuid,
            images: imageIds,
          });
          dispatch(publishProfileSuccess());
          dispatch(removeProfileDataFromLocalStorage());
          dispatch(addMarketplaceEntities(newListing));
        })
        .catch(e => {
          createMongoDBLog({ location: 'Update-Ownlisting', log: e, email: email });
          dispatch(publishProfileSuccess());
          dispatch(removeProfileDataFromLocalStorage());
          dispatch(addMarketplaceEntities(newListing));
        });
    }
  } catch (e) {
    createMongoDBLog({ location: 'Upload-Portfolio', log: e, email: email });
    log.error(e, 'denormalizeAndUpdateImages-listing-failed', { newListing });
    dispatch(publishProfileSuccess());
    dispatch(removeProfileDataFromLocalStorage());
    dispatch(addMarketplaceEntities(newListing));
  }
};

// TODO: Save projects and it's images to the listing
export const requestPublishProfileDraft = data => async (dispatch, getState, sdk) => {
  dispatch(publishProfileRequest());

  const {
    // user data
    id,
    firstName,
    lastName,
    email,
    password,
    profileImage,
    // listing data
    images,
    geolocation,
    ...rest
  } = data;

  try {
    // Create a new user
    const newUser = await sdk.currentUser.create({
      firstName,
      lastName,
      email: email,
      password,
      publicData: {
        type: LISTING_TYPE_TALENT,
      },
      protectedData: {
        email,
      },
    });

    if (newUser) {
      await dispatch(signupSuccess());
      await dispatch(login(email, password));

      // Create a new listing
      try {
        const newListing = await sdk.ownListings.create({
          title: `${firstName} ${lastName}`,
          geolocation,
          price: { amount: 1000, currency: 'USD' },
          publicData: {
            ...rest,
            type: LISTING_TYPE_TALENT,
            transactionProcessAlias: `${CONNECTION_PROCESS_NAME}/release-1`,
          },
        });

        // If there's profileImage within data, first upload the image
        // and then dispatch success actions
        if (newListing && profileImage) {
          try {
            await dispatch(uploadProfileImage(profileImage));
          } catch (e) {
            createMongoDBLog({ location: 'Upload-ProfileImage', log: e, email: email });
            console.log(e);
          }
          await finalProcess(sdk, dispatch, images, newListing, email);
        } else if (newListing) {
          await finalProcess(sdk, dispatch, images, newListing, email);
        }
      } catch (e) {
        createMongoDBLog({ location: 'Publish-listing', log: e, email: email });
        // publishing listing failed
        dispatch(publishProfileError(e));
        log.error(e, 'publish-listing-failed', { data });
      }
    }
  } catch (e) {
    createMongoDBLog({ location: 'Create-currentUser', log: e, email: email });
    // signupfailed
    dispatch(signupError(storableError(e)));
    dispatch(publishProfileError(storableError(e)));
    log.error(e, 'signup-failed', {
      email,
      firstName,
      lastName,
    });
  }
};

export const appendProfileDataSubmission = profileData => async (dispatch, getState, sdk) => {
  if (!profileData) {
    return Promise.reject(new Error('No profile data provided'));
  }

  try {
    const spreadsheetId = process.env.REACT_APP_GOOGLE_SUBMISSIONS_SPREADSHEET_ID;

    const rowsResponse = await googleSpreadsheetAPI.read({ id: spreadsheetId });
    const existingRows = rowsResponse.data.data;
    if (existingRows.some(row => row.email === profileData.email)) {
      console.log('Email already exists, not appending to spreadsheet.');
      return Promise.resolve('Email already exists');
    }

    const { firstName, lastName, name, email } = profileData;
    await googleSpreadsheetAPI.append({
      id: spreadsheetId,
      row: {
        name: `${firstName} ${lastName}`,
        email: email,
        type: LISTING_TYPE_TALENT,
      },
    });
    return Promise.resolve('Profile appended successfully');
  } catch (error) {
    console.error('Error accessing or modifying the spreadsheet:', error);
    return Promise.reject(error);
  }
};

export const loadData = params => async (dispatch, getState, sdk) => {
  const { profileData } = getState().CreateTalentPage;

  if (params.tab === 'work' && profileData) {
    return Promise.all([
      dispatch(clearUpdatedTab()),
      dispatch(loadProfileDataFromLocalStorage()),
      dispatch(appendProfileDataSubmission(profileData)),
    ]);
  }

  return Promise.all([dispatch(clearUpdatedTab()), dispatch(loadProfileDataFromLocalStorage())]);
};

export function updateorcreateMailChimpMember(data) {
  return async (dispatch, getState, sdk) => {
    const { firstName, lastName, email, step, address, registertype } = data;
    if (!email) {
      return Promise.reject(new Error('Email not provided'));
    }
    console.log(process.env.REACT_APP_MAILCHIMP_ACTIVE)
    if (process.env.REACT_APP_MAILCHIMP_ACTIVE == "true") {
      try {
        const registerdMailChimpMember = await checkMailChimpStatus({ email });
        // create mailchimp acc in step 0 or step 1 if not exist mailchimp
        if (
          registerdMailChimpMember &&
          !registerdMailChimpMember.isExist &&
          (step === 0 || step === 1)
        ) {
          await createMailChimpMember({
            email: email,
            fname: firstName ?? '',
            lname: lastName ?? '',
            registertype: registertype,
            registrationProgress: step,
            status: 'unsubscribed',
          });
        } else if (registerdMailChimpMember && registerdMailChimpMember.isExist && step >= 0) {
          if (registertype === 'Candidates') {
            await updateMailChimpMember({
              email: email,
              fname: firstName ?? '',
              lname: lastName ?? '',
              registrationProgress: step >= 5 ? 'done' : step,
              // address: address??null,
              status: step !== 5 ? (step === 6 ? 'unsubscribed' : 'unsubscribed') : 'subscribed',
            });
          } else {
            await updateMailChimpMember({
              email: email,
              fname: firstName ?? '',
              lname: lastName ?? '',
              registrationProgress: step >= 3 ? 'done' : step,
              // address: address??null,
              status: step !== 3 ? (step === 4 ? 'unsubscribed' : 'unsubscribed') : 'subscribed',
            });
          }
        }
      } catch (error) {
        console.error('Error update or create mailchimp member:', error);
        return Promise.reject(error);
      }
    }
  };
}
